import { IconButton, Stack, Divider } from "@mui/material";
import React, { useRef, useState } from "react";
import closeIcon from "../../../assets/icons/close.svg";
import menuIcon from "../../../assets/icons/menu.svg";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import styles from "./styles.module.scss";
import { useClickOutside } from "../../../hooks/use-click-outside";
import { Link } from "react-router-dom";

export const Burger = () => {
  const [anchorBurgerElement, setAnchorBurgerElement] =
    useState<HTMLButtonElement | null>(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  console.log(isOpenMenu);
  const burgerRef = useRef(null);

  useClickOutside(burgerRef, () => {
    setIsOpenMenu(false);
  });

  const handleClickBurgerButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorBurgerElement(event.currentTarget);
    setIsOpenMenu((prev) => !prev);
  };

  return (
    <>
      <IconButton onClick={handleClickBurgerButton}>
        <img
          src={menuIcon}
          id="menu"
          alt="menu"
          className={isOpenMenu ? styles.hideBurgerIcon : styles.viewBurgerIcon}
        />
        <img
          src={closeIcon}
          id="close"
          alt="close"
          className={isOpenMenu ? styles.viewBurgerIcon : styles.hideBurgerIcon}
        />
      </IconButton>
      <Popper
        ref={burgerRef}
        open={isOpenMenu}
        className={styles.popper}
        anchorEl={anchorBurgerElement}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <Stack direction={"column"} spacing={1}>
                <Link to="/#about" className={styles.link}>
                  О нас
                </Link>
                <Divider color="#6b59cc" />
                <Link to="/#questions" className={styles.link}>
                  Вопросы
                </Link>
                <Divider color="#6b59cc" />
                <Link to="/#services" className={styles.link}>
                  Виды работ
                </Link>
                <Divider color="#6b59cc" />
                <Link to="/#contacts" className={styles.link}>
                  Контакты
                </Link>
                <Divider color="#6b59cc" />
                <Link to="/gallery" className={styles.link}>
                  Галерея
                </Link>
              </Stack>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
};
