import styles from "./styles.module.scss";
import socialIcon from "../../assets/icons/social.svg";
import callIcon from "../../assets/icons/call.svg";
import moneyIcon from "../../assets/icons/money.svg";
import salesIcon from "../../assets/icons/sales.svg";
import carTimeIcon from "../../assets/icons/car-time.svg";
import paintIcon from "../../assets/icons/paint.svg";
import sparkleIcon from "../../assets/icons/sparkle.svg";
import { useMediaQuery } from "react-responsive";

export const FAQ = () => {
  const w1024 = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const w500 = useMediaQuery({
    query: "(max-width: 500px)",
  });
  return (
    <>
      {!w1024 ? (
        <div className={styles.main} id="questions">
          <div className={styles.container}>
            <p className={styles.title}>Часто задаваемые вопросы</p>
            <hr className={styles.line} />
            <div className={styles.flexTable}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={socialIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>1</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Есть ли вы в социаль&shy;ных сетях?
                </span>
                <span className={styles.answer}>
                  Да, у нас есть инстаграм профиль
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={moneyIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>2</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Сколько стоит покра&shy;ска авто?
                </span>
                <span className={styles.answer}>
                  Боковой элемент от 300р<br></br>
                  Полный облив от 5000р
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={salesIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>3</span>
                  </div>
                </div>
                <span className={styles.text}>Есть ли у вас скидки?</span>
                <span className={styles.answer}>
                  Скидки только постоянным клиентам
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={carTimeIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>4</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Какие сроки выполне&shy;ния кузовного ремонта?
                </span>
                <span className={styles.answer}>
                  Все зависит от сложности работ
                </span>
              </div>
            </div>
            <div className={styles.flexTable}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={paintIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>5</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Необходима полная по&shy;краска после ремонта отдельных частей
                  кузова?
                </span>
                <span className={styles.answer}>
                  Нет, можем красить по элементам
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={sparkleIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>6</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Делаете ли вы полировку кузова после покраски?
                </span>
                <span className={styles.answer}>
                  Делаем, но все зависит от ситуации.
                </span>
              </div>
              <div className={styles.twoCard}>
                <div className={styles.block}>
                  <img src={callIcon} alt="" className={styles.call} />
                  <p className={styles.cardText}>Обращайтесь к нам!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !w500 ? (
        <div className={styles.main} id="questions">
          <div className={styles.container}>
            <p className={styles.title}>Часто задаваемые вопросы</p>
            <hr className={styles.line} />
            <div className={styles.flexTable}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={socialIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>1</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Есть ли вы в социаль&shy;ных сетях?
                </span>
                <span className={styles.answer}>
                  Да, у нас есть инстаграм профиль
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={moneyIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>2</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Сколько стоит покра&shy;ска авто?
                </span>
                <span className={styles.answer}>
                  Боковой элемент от 300р<br></br>
                  Полный облив от 5000р
                </span>
              </div>
            </div>
            <div className={styles.flexTable}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={salesIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>3</span>
                  </div>
                </div>
                <span className={styles.text}>Есть ли у вас скидки?</span>
                <span className={styles.answer}>
                  Скидки только постоянным клиентам
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={carTimeIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>4</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Какие сроки выполне&shy;ния кузовного ремонта?
                </span>
                <span className={styles.answer}>
                  Все зависит от сложности работ
                </span>
              </div>
            </div>
            <div className={styles.flexTable}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={paintIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>5</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Необходима полная по&shy;краска после ремонта отдельных частей
                  кузова?
                </span>
                <span className={styles.answer}>
                  Нет, можем красить по элементам
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={sparkleIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>6</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Делаете ли вы полировку кузова после покраски?
                </span>
                <span className={styles.answer}>
                  Делаем, но все зависит от ситуации.
                </span>
              </div>
            </div>
            <div className={styles.flexTable}>
              <div className={styles.twoCard}>
                <div className={styles.block}>
                  <img src={callIcon} alt="" className={styles.call} />
                  <p className={styles.cardText}>Обращайтесь к нам!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.main} id="questions">
          <div className={styles.container}>
            <p className={styles.title}>Часто задаваемые вопросы</p>
            <hr className={styles.line} />
            <div className={styles.flexTable}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={socialIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>1</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Есть ли вы в социаль&shy;ных сетях?
                </span>
                <span className={styles.answer}>
                  Да, у нас есть инстаграм профиль
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={moneyIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>2</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Сколько стоит покра&shy;ска авто?
                </span>
                <span className={styles.answer}>
                  Боковой элемент от 300р<br></br>
                  Полный облив от 5000р
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={salesIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>3</span>
                  </div>
                </div>
                <span className={styles.text}>Есть ли у вас скидки?</span>
                <span className={styles.answer}>
                  Скидки только постоянным клиентам
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={carTimeIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>4</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Какие сроки выполне&shy;ния кузовного ремонта?
                </span>
                <span className={styles.answer}>
                  Все зависит от сложности работ
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={paintIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>5</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Необходима полная по&shy;краска после ремонта отдельных частей
                  кузова?
                </span>
                <span className={styles.answer}>
                  Нет, можем красить по элементам
                </span>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  <div className={styles.cardImage}>
                    <img
                      src={sparkleIcon}
                      alt="icon"
                      className={styles.cardIcon}
                    />
                  </div>
                  <div className="">
                    <span className={styles.cardNumber}>6</span>
                  </div>
                </div>
                <span className={styles.text}>
                  Делаете ли вы полировку кузова после покраски?
                </span>
                <span className={styles.answer}>
                  Делаем, но все зависит от ситуации.
                </span>
              </div>
              <div className={styles.twoCard}>
                <div className={styles.block}>
                  <img src={callIcon} alt="" className={styles.call} />
                  <p className={styles.cardText}>Обращайтесь к нам!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
