import styles from "./styles.module.scss";
import photo1 from "../../assets/images/gallery/photo_1.jpg";
import photo2 from "../../assets/images/gallery/photo_2.jpg";
import photo3 from "../../assets/images/gallery/photo_3.jpg";
import photo4 from "../../assets/images/gallery/photo_4.jpg";
import photo5 from "../../assets/images/gallery/photo_5.jpg";
import photo6 from "../../assets/images/gallery/photo_6.jpg";
import photo7 from "../../assets/images/gallery/photo_7.jpg";
import photo8 from "../../assets/images/gallery/photo_8.jpg";
import photo9 from "../../assets/images/gallery/photo_9.jpg";
import photo10 from "../../assets/images/gallery/photo_10.jpg";
import photo11 from "../../assets/images/gallery/photo_11.jpg";
import photo12 from "../../assets/images/gallery/photo_12.jpg";
import photo13 from "../../assets/images/gallery/photo_13.jpg";
import photo14 from "../../assets/images/gallery/photo_14.jpg";
import photo15 from "../../assets/images/gallery/photo_15.jpg";
import photo16 from "../../assets/images/gallery/photo_16.jpg";
import photo17 from "../../assets/images/gallery/photo_17.jpg";

export const GalleryPhoto = () => {
  return (
    <div className={styles.container}>
      <div className={styles.photosBlock}>
        <img src={photo4} alt="" className={styles.photo} />
        <img src={photo5} alt="" className={styles.photo} />
        <img src={photo6} alt="" className={styles.photo} />
        <img src={photo7} alt="" className={styles.photo} />
        <img src={photo8} alt="" className={styles.photo} />
        <img src={photo9} alt="" className={styles.photo} />
        <img src={photo10} alt="" className={styles.photo} />
        <img src={photo11} alt="" className={styles.photo} />
        <img src={photo12} alt="" className={styles.photo} />
        <img src={photo13} alt="" className={styles.photo} />
        <img src={photo14} alt="" className={styles.photo} />
        <img src={photo15} alt="" className={styles.photo} />
        <img src={photo16} alt="" className={styles.photo} />
        <img src={photo17} alt="" className={styles.photo} />
        <img src={photo1} alt="" className={styles.photo} />
        <img src={photo2} alt="" className={styles.photo} />
        <img src={photo3} alt="" className={styles.photo} />
      </div>
    </div>
  );
};
