import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";

export const Services = () => {
  const w1024 = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  return (
    <>
      {!w1024 ? (
        <div className={styles.container} id="services">
          <div className={styles.servicesInfo}>
            <h1 className={styles.title}>Виды работ:</h1>
            <div className={styles.services}>
              <div className={styles.service}>
                <span className={styles.number}>1</span>
                <p className={styles.text}>Покраска авто</p>
              </div>
              <div className={styles.service}>
                <span className={styles.number}>2</span>
                <p className={styles.text}>Полировка авто/фар</p>
              </div>
              <div className={styles.service}>
                <span className={styles.number}>3</span>
                <p className={styles.text}>Мелкий кузовной ремонт</p>
              </div>
              <div className={styles.service}>
                <span className={styles.number}>4</span>
                <p className={styles.text}>Мелкий пескоструй</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container} id="services">
          <div className={styles.servicesInfo}>
            <h1 className={styles.title}>Виды работ:</h1>
            <div className={styles.services}>
              <div className={styles.service}>
                <span className={styles.number}>1</span>
                <p className={styles.text}>Покраска авто</p>
              </div>
              <div className={styles.service}>
                <span className={styles.number}>2</span>
                <p className={styles.text}>Полировка авто/фар</p>
              </div>
            </div>
            <div className={styles.services}>
              <div className={styles.service}>
                <span className={styles.number}>3</span>
                <p className={styles.text}>Мелкий кузовной ремонт</p>
              </div>
              <div className={styles.service}>
                <span className={styles.number}>4</span>
                <p className={styles.text}>Мелкий пескоструй</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
