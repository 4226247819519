import { IconButton } from "../../components/IconButton";
import styles from "./styles.module.scss";
import phoneIcon from "../../assets/icons/mobile.svg";
import locationIcon from "../../assets/icons/location.svg";
import timeIcon from "../../assets/icons/time.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export const Contacts = () => {
  const w1024 = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  return (
    <>
      {!w1024 ? (
        <div className={styles.container} id="contacts">
          <h1 className={styles.title}>Контакты</h1>
          <hr className={styles.line}></hr>
          <div className={styles.contacts}>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <img src={phoneIcon} alt="phone" className={styles.image} />
              </IconButton>
              <span className={styles.text}>+375 (29) 356-06-23</span>
            </div>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <img
                  src={locationIcon}
                  alt="location"
                  className={styles.image}
                />
              </IconButton>
              <span className={styles.text}>
                Гомель, Советский район,<br></br> Объездная улица, 32
              </span>
            </div>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <img src={timeIcon} alt="time" className={styles.image} />
              </IconButton>
              <span className={styles.text}>
                График работы:<br></br>Будние дни с 10 до 18<br></br> Суббота с
                10 до 15
              </span>
            </div>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <Link to="https://www.instagram.com/artkuzov.by?igsh=MWE5M3J5MGs0YXpsOQ==">
                  <img
                    src={instagramIcon}
                    alt="social"
                    className={styles.image}
                  />
                </Link>
              </IconButton>
              <span className={styles.text}>ПОКРАСКА АВТО | ГОМЕЛЬ</span>
            </div>
          </div>
          <iframe
            className={styles.map}
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A7cd931134dda73f340e6c43ec811eb22ad16354e3f1d53a9592aa717ae5437fe&amp;source=constructor"
            width="831"
            height="430"
            frameBorder="0"
            title="big"
          ></iframe>
        </div>
      ) : (
        <div className={styles.container} id="contacts">
          <p className={styles.title}>Контакты</p>
          <hr className={styles.line}></hr>
          <div className={styles.contacts}>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <Link to="tel:+375 (29) 356-06-23">
                  <img src={phoneIcon} alt="phone" className={styles.image} />
                </Link>
              </IconButton>
              <span className={styles.text}>+375 (29) 356-06-23</span>
            </div>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <Link to="https://www.instagram.com/artkuzov.by?igsh=MWE5M3J5MGs0YXpsOQ==">
                  <img
                    src={instagramIcon}
                    alt="social"
                    className={styles.image}
                  />
                </Link>
              </IconButton>
              <span className={styles.text}>ПОКРАСКА АВТО | ГОМЕЛЬ</span>
            </div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <img src={timeIcon} alt="time" className={styles.image} />
              </IconButton>
              <span className={styles.text}>
                График работы:<br></br>Будние дни с 10 до 18<br></br> Суббота с
                10 до 15
              </span>
            </div>
            <div className={styles.contactBlock}>
              <IconButton className={styles.contactsButton}>
                <img
                  src={locationIcon}
                  alt="location"
                  className={styles.image}
                />
              </IconButton>
              <span className={styles.text}>
                Гомель, Советский район,<br></br> Объездная улица, 32
              </span>
            </div>
          </div>
          <iframe
            className={styles.map}
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A7cd931134dda73f340e6c43ec811eb22ad16354e3f1d53a9592aa717ae5437fe&amp;source=constructor"
            width="831"
            height="430"
            frameBorder="0"
            title="mini"
          ></iframe>
        </div>
      )}
    </>
  );
};
