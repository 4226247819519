import styles from "./styles.module.scss";

export const About = () => {
  return (
    <div className={styles.container} id="about">
      <h1 className={styles.mainTitle}>СТО-Artkuzov</h1>
      <article className={styles.main}>
        <p className={styles.mainText}>
          Хотите, чтобы ваш автомобиль снова сиял яркими красками и блестел на
          солнце? Обратитесь в компанию Artkuzov - профессионалы в области
          покраски, полировки и мелкого ремонта автомобиля любой сложности! Наши
          опытные мастера сделают ваш авто как новенький, восстановив его
          первозданный вид. Доверьте свой автомобиль в надежные руки и получите
          результат, который превзойдет все ваши ожидания. Artkuzov - забота о
          вашем автомобиле на высшем уровне!
        </p>
        <p className={styles.title}>Почему стоит обратиться именно к нам?</p>
        <ul>
          <li>
            <p className={styles.text}>У нас опыт работы более 15-ти лет!</p>
          </li>
          <li>
            <p className={styles.text}>
              Выполняем работу качественно и в срок!
            </p>
          </li>
          <li>
            <p className={styles.text}>Демократически цены!</p>
          </li>
          <li>
            <p className={styles.text}>
              В нашей команде квалифицированные специалисты!
            </p>
          </li>
        </ul>
      </article>
    </div>
  );
};
