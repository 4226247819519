import styles from "./styles.module.scss";

export const Intro = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textBlock}>
        <p className={styles.title}>ДАРИМ ВАШЕМУ АВТО НОВЫЙ ОБЛИК!</p>
        <p className={styles.subtitle}>
          Отличный сервис для вашего надежного друга на дороге!
        </p>
      </div>
    </div>
  );
};
