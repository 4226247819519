import styles from "./styles.module.scss";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useScrollToAnchor } from "../../hooks/use-scroll-to-anchor";
import { Burger } from "./Burger";
import { useMediaQuery } from "react-responsive";
import { IconButton } from "../../components/IconButton";
import phoneIcon from "../../assets/icons/call-header.svg";

export const Header = () => {
  useScrollToAnchor();
  const w768 = useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <header className={styles.header}>
      <nav className={styles.navigation}>
        <div className={styles.logoWrap}>
          <Link to="/">
            <img src={logo} alt="logo" className={styles.logo} />
          </Link>
        </div>
        <div className={styles.navInfo}>
          <div className={styles.phoneWrap}>
            {w768 && (
              <IconButton className={styles.phoneIcon}>
                <Link to="tel:+375 (29) 356-06-23">
                  <img src={phoneIcon} alt="phone" className={styles.image} />
                </Link>
              </IconButton>
            )}
            <Link to="tel:+375 (29) 356-06-23" className={styles.phone}>
              +375 (29) 356-06-23
            </Link>
          </div>
          <div className={styles.links}>
            {!w768 ? (
              <>
                <Link to="/#about" className={styles.link}>
                  О нас
                </Link>
                <Link to="/#questions" className={styles.link}>
                  Вопросы
                </Link>
                <Link to="/#services" className={styles.link}>
                  Виды работ
                </Link>
                <Link to="/#contacts" className={styles.link}>
                  Контакты
                </Link>
                <Link to="/gallery" className={styles.link}>
                  Галерея
                </Link>
              </>
            ) : (
              <Burger />
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};
