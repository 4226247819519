import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material/IconButton";

export interface IconButtonProps extends MuiIconButtonProps {}

export const IconButton = ({ children, style, ...props }: IconButtonProps) => {
  return (
    <MuiIconButton {...props} style={{ ...style }}>
      {children}
    </MuiIconButton>
  );
};
