import styles from "./styles.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.column}>
            <span className={styles.title}>
              © Artkuzov 2024.<br></br> All Rights
              <br className={styles.br}></br> Reserved
            </span>
            <span className={styles.text}>
              По вопросам с разработкой: <br></br>
              kovallll.e@gmail.com
            </span>
          </div>
          <div className={styles.column}>
            <span className={styles.title}>
              Данные <br className={styles.br}></br>регистрации:
            </span>
            <p className={styles.text}>
              ИП Холопов Сергей Григорьевич <br></br>УНП 491539956
            </p>
          </div>
          <div className={styles.column}>
            <span className={styles.title}>
              Банковские <br className={styles.br}></br>реквизиты:{" "}
            </span>
            <p className={styles.text}>
              р/с BY46ALFA30132A<br className={styles.br}></br>
              32440010270000 <br></br>
              ЗАО «Альфа-Банк», г. Минск,<br></br> ул. Сурганова д.43, <br></br>
              БИК ALFABY2X
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
