import { YMaps } from "@pbe/react-yandex-maps";
import { Services } from "../items/Services";
import { Contacts } from "../items/Contacts";
import { FAQ } from "../items/FAQ";
import { About } from "../items/About";
import { Intro } from "../items/Intro";

export const Main = () => {
  return (
    <>
      <Intro />
      <About />
      <FAQ />
      <Services />
      <YMaps>
        <Contacts />
      </YMaps>
    </>
  );
};
